.ant-layout.bo-login {
	background: url('../../assets/images/loginBackground.jpg') no-repeat no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
}

.ant-layout-content.bo-login-content {
	display: flex;
	justify-content: center;
	flex: auto;
	flex-direction: column;
	align-items: center;
	background: transparent;
}



.bo-login .bo-login-card {
	width: 400px;
	/* background: transparent; */
	position: absolute;
	box-shadow: 0 0 5px;
	right: 10%;
}

.bo-login .bo-login-card:before {
	position: absolute;
	content: " ";
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: url('../../assets/images/loginBackground.jpg') no-repeat no-repeat;
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	filter: brightness(2) blur(5px) opacity(.5);
	overflow: hidden;
}

.bo-login .bo-login-card .ant-card-head {
	background: rgba(255,255,255,0.8);
}

.bo-login > .bo-login-footer {
	text-align: center;
}

.bo-login-forgot {
	float: right;
}

.bo-login-remember {
	float: left;
}

.bo-login-button {
	width: 100%;
}