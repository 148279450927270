
body {
  display: flex;
  flex: auto;
  flex-direction: column;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#root > div {
  display: flex;
  flex: 1;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.ant-layout-header {
  background: #fff;
}

.ant-layout-content {
  /* background: #fff; */
  padding: 24px;
  /* margin: 24px 24px 0; */
}

.ant-table-wrapper {
  background: #fff;
}

.posTabs.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
}

.posTabs.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  
}

.ant-layout-header {
  padding: 8px 24px;
  height: auto !important;
  /* border-bottom: 1px solid #dfdfdf;
  background: #efefef; */
}

.ant-layout-header .ant-tabs {
  margin: 0 -24px;
}

.ant-table-pagination {
  margin: 16px 24px;
}

.ant-layout-header h2 {
  line-height: 32px;
}

.ant-table-filter-dropdown {
  min-width: 200px !important;
}


.bo-filters {
  padding: 16px 24px;
  border-top: 1px solid #dfdfdf;
  background: #efefef;
}

.ant-layout {
  background: #fefefe;
}

.ant-page-header {
  box-shadow: 0 0 5px #ccc;
}

/* .ant-table tr > *:first-child {
  padding: 10px 8px 10px 24px !important;
}

.ant-table tr > *:last-child {
  padding: 10px 24px 10px 8px !important;
} */

.ant-table-pagination {
  margin-right: 24px;
}

.ant-table-wrapper {
  /* border-bottom: 1px solid #dfdfdf; */
}

.bo-select-loading {
  text-align: center;
}

.bo-due-past {
  color: #ff0000;
}

.bo-paid {
  color: darkgreen;
}
.bo-not-paid {
  color: #ff0000;
}

.ant-table {
  box-shadow: 0 0 5px #eee;
}

.ant-table tr:hover td {
  background: inherit !important;
}

.bo-nested-table .ant-table-expanded-row > td:last-child {
  padding: 0 !important;
}
.bo-nested-table .ant-table-expanded-row .ant-table {
  box-shadow: none !important;
}

.bo-nested-table .ant-table-expanded-row .ant-table-wrapper {
  border-bottom: 0;
}

.bo-nested-table .ant-table-expanded-row .ant-table-wrapper table {
  border: 0;
}

.bo-nested-table .ant-table-expanded-row .ant-table-placeholder {
  border-width: 0 !important; 
  border-bottom-width: 1px !important;
  border-radius: 0;
}

.bo-nested-table .ant-table-expanded-row .ant-table-footer {
  border: 0 !important; 
  border-top: 1px solid #e9e9e9 !important;
  border-bottom: 1px solid #e9e9e9 !important;
  border-radius: 0;
}

.ant-table, .ant-table-head, .ant-table-footer, table {
  border-radius: 0 !important;
}

.bo-nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th {
  border-bottom: 1px solid #e9e9e9;
}

/* .bo-nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th:first-child {
  padding-left: 0;
}

.bo-nested-table .ant-table-expanded-row > td:last-child .ant-table-row td:first-child {
  padding-left: 0;
} */



.bo-nested-table .ant-table-expanded-row .ant-table-thead > tr > th {
  background: none;
}

.bo-nested-table .ant-table-expanded-row .ant-table-tbody > tr > td {
  background: #fff;
}

.bo-nested-table .table-operation a:not(:last-child) {
  margin-right: 24px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.bo-top-border td {
  border-top: 1px solid #e9e9e9;
}

.bo-nested-table .ant-table-expanded-row .ant-table-wrapper.bo-calculation-details,
.bo-nested-table .ant-table-expanded-row .ant-table-wrapper.bo-pricechange-details {
  border-left: 1px solid #e9e9e9;
  /* border-bottom: 1px solid #e9e9e9; */
}
  .bo-calculation {
    border-left: 1px solid #e8e8e8;
  }

.bo-calculation-summary tr td:first-child,
.bo-pricechange-summary tr td:first-child  {
  background: none !important;
  font-weight: 500;
  color:  rgba(0, 0, 0, 0.85);
  text-align: right;
}

.bo-calculation-signature-print, .bo-calculation-info-print,
.bo-pricechange-signature-print, .bo-pricechange-info-print {
  display: none;
}

.ant-table-pagination.ant-pagination {
  margin: 16px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  word-break: initial !important;
}

iframe[id^="printElement_"]
 {
    width: 100%;
    height: 100%;
   
    position: relative !important;
  }

.posTotal {
  font-family: 'Roboto Mono', monospace;
}

@media print {
  
  * {
    font-size: 9px;
    color: black;
  }

  .ant-table tr > *:first-child {
    padding:  4px 3px !important;
  }

  .ant-table tr > *:last-child {
    padding:  4px 3px !important;
  }

  table th {
    white-space: nowrap;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
    background: #aaa !important;
  }

   table td {
    white-space: normal;
     border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
}


  table th, table td {
    padding: 4px 3px !important;
  }

  table {
    border-top: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
    margin-top: 15px;
  }

  h2 {
    font-size: 12px;
    padding: 15px 0 0 0;
  }

  table col {
    width: auto !important;
    min-width: auto !important;
  }

  .ant-table-expanded-row {
    width: 100%;
  }



  .bo-calculation-info-print, .bo-calculation-signature-print, .bo-pricechange-info-print, .bo-pricechange-signature-print {
   
    display: block;
  }
  .bo-calculation-signature-print, .bo-pricechange-signature-print {
    margin-top: 30px;
  }

  .bo-calculation .ant-table-footer, .bo-pricechange .ant-table-footer {
    display: none;
  }

  .bo-calculation .bo-column-actions, .bo-pricechange .bo-column-actions {
    display: none;
  }

  .bo-calculation-summary-container > div, .bo-pricechange-summary-container > div  {
    float: none;
  }

  .bo-calculation-summary-col1 {
    width: 50%;
  }

  .bo-calculation-summary-col2 {
    width: 100%;
  }
  .bo-calculation {
    border-left: none;
  }
  .ant-table-wrapper {
    border-bottom: none;
  }
}